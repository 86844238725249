import { FunctionComponent } from 'react';
import './Link.scss';

type ContentLinkProps = {
  text: string;
  currentModal: string;
  setCurrentModal: (modalName: string) => void;
};

export const ContentLink: FunctionComponent<ContentLinkProps> = ({
  text,
  currentModal,
  setCurrentModal,
}: ContentLinkProps) => {
  const onClick = () => {
    if (currentModal === text) {
      setCurrentModal('');
    } else {
      setCurrentModal(text);
    }
  };

  return (
    <div>
      <div className="link ContentLink">
        <div className="link-text" onClick={onClick}>
          {text}
        </div>
      </div>
    </div>
  );
};
